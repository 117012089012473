import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import AssignmentIcon from "@mui/icons-material/Assignment";
import StyleIcon from "@mui/icons-material/Style";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import SearchIcon from "@mui/icons-material/Search";
import Link from "@mui/material/Link";
import BusinessIcon from "@mui/icons-material/Business";
import GroupIcon from "@mui/icons-material/Group";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SellIcon from "@mui/icons-material/Sell";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, styled } from "@mui/system";
import { Card } from "@mui/material";

const CardRoot = styled(Card)(() => ({
  textAlign: "center",
}));

const MainListItems = ({
  userRole,
  onShowReportes,
  onShowProfesiones,
  onShowTarjeta,
  onShowPromociones,
  onShowUsuarios,
  onShowNegocios,
  onShowNegocio,
  onShowManagement,
}) => (
  <React.Fragment>
    <CardRoot>
      <b>{userRole}</b>
    </CardRoot>
    {/* opciones que se muestran a todos los roles */}
    <ListItemButton onClick={onShowTarjeta}>
      <ListItemIcon>
        <StyleIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    {userRole !== "USER" && userRole !== "NA" && (
      <ListItemButton onClick={onShowManagement}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Tarjetas" />
      </ListItemButton>
    )}
    {userRole !== "NA" && (
      <>
        <ListItemButton onClick={onShowReportes}>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Reportes" />
        </ListItemButton>
        <ListItemButton onClick={onShowPromociones}>
          <ListItemIcon>
            <SellIcon />
          </ListItemIcon>
          <ListItemText primary="Promociones" />
        </ListItemButton>
        <ListItemButton onClick={onShowProfesiones}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary="Buscar" />
        </ListItemButton>
      </>
    )}
    {userRole === "ADMIN" && (
      <ListItemButton onClick={onShowUsuarios}>
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary="Usuarios" />
      </ListItemButton>
    )}
    {userRole === "ADMIN" && (
      <ListItemButton onClick={onShowNegocios}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Negocios" />
      </ListItemButton>
    )}
    {(userRole === "PROPIETARIO" ||
      userRole === "GERENTE" ||
      userRole === "NA") && (
      <ListItemButton onClick={onShowNegocio}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Negocio" />
      </ListItemButton>
    )}
    {/* <ListItemButton
      component={Link}
      to={"https://onlytap.imporsuit.com/"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ListItemIcon>
        <ProductionQuantityLimitsIcon />
      </ListItemIcon>
      <ListItemText primary="Accesorios" />
    </ListItemButton> */}
  </React.Fragment>
);

const SecondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Cuenta
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <UpgradeIcon />
      </ListItemIcon>
      <ListItemText primary="UPGRADE" />
    </ListItemButton>
  </React.Fragment>
);

export { MainListItems, SecondaryListItems };
