import React, { useEffect, useState } from "react";
import Form from "./promocionesForm";
import axios from "axios";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { ModalVerPuntos } from "../Components/modals/index";
import { styled, Box, Icon, Menu } from "@mui/material";
import MuiDialogActions from "@mui/material/DialogActions";
import ModalDesactivar from "../../../views/Usuarios/ModalDesactivar";

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

const styleContainer = {
  display: "flex",
  justifyContent: "space-between",
  padding: "0px 10px 0px 10px",
  backgroundColor: "#DDDDDD", // rojo claro
};

const styleGanados = {
  backgroundColor: "#d4edda", // verde claro
  padding: "10px",
  borderRadius: "5px",
  margin: "5px",
};

const styleCanjeados = {
  backgroundColor: "#D3D3D3", // rojo claro
  padding: "10px",
  borderRadius: "5px",
  margin: "5px",
};

const styleDisponibles = {
  backgroundColor: "#ffffff", // amarillo claro
  padding: "10px",
  borderRadius: "5px",
  margin: "5px",
};

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

function Promociones({ rol, entidad }) {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [tarjeta, setTarjeta] = useState(null);
  const [tarjetas, setTarjetas] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [ShowModalVerPuntos, setShowModalVerPuntos] = useState(false);
  const [anchorE, setAnchorE] = React.useState(null);
  const [listPuntos, setListPuntos] = useState([]);
  const [estilo, setEstilo] = useState(null);
  const [configuracion, setConfiguracion] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [promoToEdit, setPromoToEdit] = useState(null);

  function handleClick(event, promo) {
    setPromoToEdit(promo);
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  const handleAbrir = () => {
    setPromoToEdit(null);
    setOpen(!open);
  };

  const handleEdit = () => {
    console.log(promoToEdit);
    if (promoToEdit) {
      setOpen(true);
      handleCloseMenu();
    }
  };

  const handlePromocionGuardada = (promo) => {
    // Si la promo ya existe en la lista (comprobando por su id), actualiza esa promo en lugar de duplicarla
    setTarjetas((prevTarjetas) => {
      const existe = prevTarjetas.some((p) => p.id === promo.id);
      if (existe) {
        // Reemplazar la promoción existente
        return prevTarjetas.map((p) => (p.id === promo.id ? promo : p));
      } else {
        // Si no existe, añadirla (es una nueva promoción)
        return [...prevTarjetas, promo];
      }
    });
  };

  useEffect(() => {
    const tarjetaGuardada = JSON.parse(localStorage.getItem("Tarjeta"));
    console.log(tarjetaGuardada.wallet);
    setTarjeta(tarjetaGuardada.wallet);
  }, []);

  const [sumaPuntos, setSumaPuntos] = useState(0);
  const [sumaEstadoCero, setSumaEstadoCero] = useState(0);
  const [sumaEstadoUno, setSumaEstadoUno] = useState(0);

  useEffect(() => {
    console.log(tarjeta);
    if (tarjeta) {
      const { sumaCero, sumaUno } = tarjeta.reduce(
        (acc, pago) => {
          if (parseInt(pago.estado) === 0) {
            acc.sumaCero += parseFloat(pago.total_puntos);
          } else if (parseInt(pago.estado) === 2) {
            acc.sumaUno += parseFloat(pago.total_puntos);
          }
          return acc;
        },
        { sumaCero: 0, sumaUno: 0 }
      );
      setSumaPuntos(sumaCero - sumaUno);
      setSumaEstadoCero(sumaCero);
      setSumaEstadoUno(sumaUno);
    }
  }, [tarjeta]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          baseUrl + "promociones/fidelizacion/listar"
        );
        let datos = response.data.data;
        let datosJSON = JSON.stringify(datos);
        localStorage.setItem("allCards", datosJSON);
        setTarjetas(response.data.data);
        // console.log(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchConfiguracion = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        };
        const { data } = await axios.post(
          baseUrl + "configuracion/puntos/listar",
          null,
          config
        );
        console.log(data);
        setConfiguracion(data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchConfiguracion();
  }, []);

  const handleVerWallet = (pagos) => {
    console.log(pagos);
    setAnchorE(null);
    setListPuntos(pagos);
    setShowModalVerPuntos(true);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {(rol === "ADMIN" || rol === "PROPIETARIO" || rol === "GERENTE") && (
          <>
            <Button
              onClick={handleAbrir}
              variant="contained"
              sx={{
                width: "30%",
                marginBottom: "10px",
                margin: "9px 0px",
              }}
            >
              Crear
            </Button>
          </>
        )}
      </div>
      {open && (
        <Form
          entidad={entidad}
          promo={promoToEdit}
          onClose={() => {
            setOpen(false);
            setPromoToEdit(null);
          }}
          onPromocionGuardada={handlePromocionGuardada}
        />
      )}
      <div
        className=""
        style={{
          borderRadius: "10px",
          border: "1px solid #E0E0E0",
          padding: "20px",
          backgroundColor: "#DDDDDD",
        }}
      >
        <div style={styleContainer}>
          <h2>Puntos globales</h2>
          <StyledButton
            variant="outlined"
            type="submit"
            color="primary"
            onClick={() => handleVerWallet(tarjeta)}
            // disabled={!tarjeta.wallet.length}
            sx={{
              width: "max-content",
              fontWeight: "bold",
              border: "2px solid",
              color: !estilo ? "#437294" : estilo.color_principal,
              borderRadius: "10px",
            }}
          >
            Detalle puntos
          </StyledButton>
          {configuracion[0]?.cantidad_puntos && (
            <>
              <p style={styleGanados}>Ganados: {sumaEstadoCero}</p>
              <p style={styleCanjeados}>Canjeados: {sumaEstadoUno}</p>
              <p style={styleDisponibles}>
                <b>Disponibles: {sumaEstadoCero - sumaEstadoUno}</b>
              </p>
            </>
          )}
        </div>
        <div
          className=""
          style={{
            borderRadius: "10px",
            border: "1px solid #E0E0E0",
            padding: "20px",
            backgroundColor: "white",
          }}
        >
          <h2>Promociones Disponibles</h2>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              textAlign: "right",
            }}
          >
            {tarjetas.map((promo) => (
              <Card
                key={promo.id}
                sx={{
                  marginBottom: "20px",
                  borderRadius: "10px",
                  backgroundColor:
                    sumaPuntos / configuracion[0]?.cantidad_puntos >=
                    parseFloat(
                      promo?.puntos / configuracion[0]?.cantidad_puntos
                    )
                      ? "#F5F5F5"
                      : "#E0E0E0",
                  border:
                    sumaPuntos / configuracion[0]?.cantidad_puntos >=
                    parseFloat(
                      promo?.puntos / configuracion[0]?.cantidad_puntos
                    )
                      ? "1px solid #E0E0E0"
                      : "2px dashed #000000",
                  width: "100%",
                  flexBasis: "calc(100% - 20px)",
                  opacity:
                    sumaPuntos / configuracion[0]?.cantidad_puntos >=
                    parseFloat(
                      promo?.puntos / configuracion[0]?.cantidad_puntos
                    )
                      ? 1
                      : 0.5,
                  "@media (min-width: 768px)": {
                    flexBasis: "calc(50% - 20px)",
                  },
                  "@media (min-width: 960px)": {
                    flexBasis: "calc(50% - 20px)",
                  },
                  "@media (min-width: 1200px)": {
                    flexBasis: "calc(25% - 20px)",
                  },
                }}
              >
                <div
                  style={{
                    display: "flex", // Alineación en la misma línea
                    justifyContent: "space-between", // Espacio entre los elementos
                    alignItems: "center", // Alineación vertical
                    marginBottom: "10px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      paddingLeft: "9px", // Padding de 9px antes del texto "Válido en:"
                    }}
                  >
                    Válido en: <b>{promo?.comercio?.nombre_comercial}</b>
                  </span>
                  <div
                    style={{
                      display: "inline-block",
                      backgroundColor: "#7ed6df",
                      borderRadius: "10%",
                      color: "black",
                      padding: "10px",
                    }}
                  >
                    {promo.puntos} Puntos
                  </div>
                </div>
                <CardMedia
                  component="img"
                  height="210"
                  image={promo.imagen}
                  alt={promo.nombre}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: "bold" }}
                    title={`${promo.descripcion} `}
                  >
                    {promo.nombre}
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: "10px" }}>
                    {promo.descripcion}
                  </Typography>
                  {sumaPuntos / configuracion[0]?.cantidad_puntos <
                    parseFloat(
                      promo.puntos / configuracion[0]?.cantidad_puntos
                    ) && (
                    <Typography
                      variant="body2"
                      color="error"
                      sx={{ marginTop: "10px" }}
                    >
                      No tienes suficientes puntos para canjear esta promoción.
                    </Typography>
                  )}
                  <DialogActions sx={{ alignSelf: "start" }}>
                    <Box>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        align="left"
                        disabled={cargando}
                        onClick={(e) => handleClick(e, promo)}
                        sx={{
                          color: "#ffffff",
                          width: "max-content",
                          background: !estilo
                            ? "#053D6B"
                            : estilo.color_principal,
                          borderRadius: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        <Icon sx={{ mr: "10px" }}>settings</Icon>
                        Opciones
                      </StyledButton>

                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                      >
                        <StyledButton
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={cargando}
                          onClick={() => handleEdit()}
                          sx={{
                            color: "#ffffff",
                            width: "max-content",
                            background: !estilo
                              ? "#053D6B"
                              : estilo.color_principal,
                            borderRadius: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          <Icon sx={{ mr: "10px" }}>create</Icon>
                          Editar promo
                        </StyledButton>

                        <ModalDesactivar id={promo.id} />
                      </Menu>
                    </Box>
                  </DialogActions>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <ModalVerPuntos
        isVisible={ShowModalVerPuntos}
        onClose={() => setShowModalVerPuntos(false)}
        pagos={listPuntos}
        puntosG={configuracion[0]?.cantidad_puntos}
      />
    </div>
  );
}

export default Promociones;
