import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Card, Grid, Icon, MenuItem, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useEffect } from "react";
import { CambiarEstadoEntidadDialog } from "./CambiarEstadoEntidadDialog";
import { SelectValidator } from "react-material-ui-form-validator";
import CircularProgress from "@mui/material/CircularProgress";
import { useNumericStringValues } from "../../utils/useNumbers";
import SaveIcon from "@mui/icons-material/Save";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  border: 0,
  marginTop: "0px",
  marginBottom: "0px",
}));

const GridContainer = styled(Grid)(() => ({
  display: "flex",
  alignContent: "start",
  alignItems: "center",
  borderBottom: "2px solid #DCDCDC",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const InformacionEntidadForm = ({ comercio }) => {
  const [estilo, setEstilo] = useState(null);
  const [numericValues, validInputChange] = useNumericStringValues({
    ruc: comercio?.ruc,
    telefono: comercio?.telefono,
    whatsapp: comercio?.whatsapp,
  });

  const initInfo = {
    nombre_comercial: "",
    razon_social: "",
    ruc: "",
    direccion: "",
    telefono: "",
    whatsapp: "",
    correo: "",
    logo: "",
    sitio_web: "",
    estado: true,
    tipo_comercio: "",
  };
  
  const [entidad, setEntidad] = useState(comercio || initInfo);
  const [entidadAEditar, setEntidadAEditar] = useState(comercio || initInfo);
  const [disabledFields, setDisabledFields] = useState(true);

  const handleInputChange = (e) => {
    setEntidad({
      ...entidad,
      [e.target.name]: e.target.value,
    });
  };
  const handleTipoEntidadChange = (e) => {
    return setEntidad({
      ...entidad,
      tipo_entidad: e.target.value,
    });
  };

  const getEstadoModal = (estado) => {
    // console.log('Estado desde el modal: ', estado);
    setEntidad({
      ...entidad,
      estado,
    });
  };

  return entidad === null ? (
    <CircularProgress />
  ) : (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box minWidth={"85%"} sx={{ padding: 4, alignSelf: "center" }}>
        <Card sx={{ padding: 2, alignContent: "center" }}>
          {/* TODO: Mostrar el logo solo si existe */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "90%",
              height: 150,
            }}
          >
            {entidadAEditar?.logo && (
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "scale-down",
                  // transform: 'scale(0.8)'
                }}
                alt={`Logo ${entidadAEditar?.nombre_comercial}`}
                src={entidadAEditar?.logo}
              />
            )}
          </Box>

          <ValidatorForm>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                pb: 3,
              }}
            >
              <Typography variant="p">
                {entidad?.tipo_comercio?.nombre_tipo || ""}{" "}
              </Typography>
            </Box>

            <GridContainer container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="p" fontWeight={"bold"}>
                  Nombre comercial{" "}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  disabled={!disabledFields}
                  type="text"
                  name="nombre_comercial"
                  placeholder="Nombre comercial"
                  id="standard-basic"
                  variant="standard"
                  value={entidad?.nombre_comercial || ""}
                  onChange={handleInputChange}
                  validators={["required"]}
                  errorMessages={["Este campo es requerido"]}
                />
              </Grid>
            </GridContainer>
            <GridContainer container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="p" fontWeight={"bold"}>
                  Razon Social{" "}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  disabled={!disabledFields}
                  type="text"
                  name="razon_social"
                  placeholder="Razon social"
                  id="standard-basic"
                  variant="standard"
                  value={entidad?.razon_social || ""}
                  onChange={handleInputChange}
                  validators={["required"]}
                  errorMessages={["Este campo es requerido"]}
                />
              </Grid>
            </GridContainer>

            <GridContainer container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="p" fontWeight={"bold"}>
                  RUC
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="text"
                  name="ruc_empresa"
                  placeholder="RUC"
                  id="standard-basic"
                  variant="standard"
                  value={numericValues?.ruc}
                  onChange={validInputChange}
                  validators={[
                    "required",
                    "minStringLength: 13",
                    "maxStringLength: 13",
                  ]}
                  errorMessages={[
                    "Este campo es requerido",
                    "El RUC debe tener 13 caracteres",
                    "El RUC debe tener 13 caracteres",
                  ]}
                />
              </Grid>
            </GridContainer>
            <GridContainer container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="p" fontWeight={"bold"}>
                  Dirección
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="text"
                  name="direccion"
                  placeholder="Direccion"
                  id="standard-basic"
                  variant="standard"
                  value={entidad?.direccion || ""}
                  onChange={handleInputChange}
                  validators={["required"]}
                  errorMessages={["Este campo es requerido"]}
                />
              </Grid>
            </GridContainer>
            <GridContainer container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="p" fontWeight={"bold"}>
                  Telefono
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="text"
                  name="telefono"
                  placeholder="Telefono"
                  id="standard-basic"
                  variant="standard"
                  value={numericValues?.telefono || ""}
                  onChange={validInputChange}
                  errorMessages={[
                    "Este campo es requerido",
                    "Debe tener 10 números",
                    "Debe tener 10 números",
                  ]}
                  validators={[
                    "required",
                    "minStringLength: 10",
                    "maxStringLength: 10",
                  ]}
                />
              </Grid>
            </GridContainer>
            <GridContainer container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="p" fontWeight={"bold"}>
                  Whatsapp
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="text"
                  name="whatsapp"
                  placeholder="Whatsapp"
                  id="standard-basic"
                  variant="standard"
                  value={numericValues?.whatsapp || ""}
                  onChange={validInputChange}
                  errorMessages={[
                    "Este campo es requerido",
                    "Debe tener 10 números",
                    "Debe tener 10 números",
                  ]}
                  validators={[
                    "required",
                    "minStringLength: 10",
                    "maxStringLength: 10",
                  ]}
                />
              </Grid>
            </GridContainer>

            <GridContainer container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="p" fontWeight={"bold"}>
                  Correo
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="text"
                  name="correo"
                  placeholder="Correo"
                  id="standard-basic"
                  variant="standard"
                  value={entidad?.correo || ""}
                  onChange={handleInputChange}
                  errorMessages={[
                    "Este campo es requerido",
                    "Debe tener formato de email",
                  ]}
                  validators={["required", "isEmail"]}
                />
              </Grid>
            </GridContainer>

            <GridContainer container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="p" fontWeight={"bold"}>
                  Sitio web
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="text"
                  name="correo_coorporativo"
                  placeholder="Correo Corporativo"
                  id="standard-basic"
                  variant="standard"
                  value={entidad?.sitio_web || ""}
                  onChange={handleInputChange}
                  errorMessages={["Este campo es requerido"]}
                  validators={["required"]}
                />
              </Grid>
            </GridContainer>

            <GridContainer container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="p" fontWeight={"bold"}>
                  Estado
                </Typography>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* <SmallEstadoActivo onClick={onEstadoClick} activo={estado}>{(estado)?'Activo':'Inactivo'}</SmallEstadoActivo>
                 */}
                <CambiarEstadoEntidadDialog
                  estadoEntidad={entidad?.estado || true}
                  getEstadoEntidad={getEstadoModal}
                />
              </Grid>
            </GridContainer>

            <Box
              sx={{
                pt: 3,
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <StyledButton
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  color: "#ffffff",
                  width: "max-content",
                  background: !estilo ? "#053D6B" : estilo.color_principal,
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
              >
                <SaveIcon />
                Guardar Información
              </StyledButton>
            </Box>
          </ValidatorForm>
        </Card>
      </Box>
    </Box>
  );
};
